import moment from 'moment';

jQuery( document ).ready(function($) {

  // videos slider
  $('.videos-wrapper.slider').slick({
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });

  // Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Kent%20Jamz/events?app_id=45PRESS_kent-jamz',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
        const events = $( '#events' );
        let html = '';
        if ( data.length ) {
            for ( let event of data ) {
                const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                html += '<div class="event" data-bit-id="' + event.id + '">';
                html += '<div class="event-date">' + moment( event.datetime ).format( 'MMM DD YYYY' ) + '</div>';
                html += '<div class="event-venue">' + event.venue.name + '</div>';
                html += '<div class="event-location">' + event.venue.city + ', ' + event.venue.region + '</div>';
                html += '<div class="event-links">';
                html += '<a href="' + event.url + '" target="_blank" class="btn btn-pink">Buy Tickets</a>';
                html += '</div>';
                html += '</div>';
            }
            events.html( html );
        } else {
            events.html( 'No upcoming events.' );
        }
    }
} );
});